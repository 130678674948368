import styled from 'styled-components';

export const Button = styled.button`
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  padding: 10px 33px;
  transition: all 0.4s;
  margin: 0 5px;

  &.btn-classic {
    border-radius: 5px !important;
  }

  &.btn-classic-rounded {
    border-radius: 25px !important;
  }

  &.btn-classic-icon {
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
  }

  &.btn-classic-circle {
    border-radius: 50%;
    font-size: 14px;
    padding: 10px;
    line-height: 18px;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    background-color: ${props => props.theme.textInputColor} !important;
    border-color: ${props => props.theme.textInputColor} !important;

    &:hover {
      color: #fff !important;
      background-color: ${props => props.theme.textInputColor} !important;
      border-color: ${props => props.theme.textInputColor} !important;
    }
  }
`;

export const CopyMessage = styled.span<{$isActive: boolean}>`
  position: absolute;
  bottom: 20px;
  left: -10px;
  border-radius: 5px;
  width: fit-content;
  color: #000000;
  font-size: 12px;
  opacity: ${props => (props.$isActive ? '1' : '0')};
  z-index: 3;
`;
