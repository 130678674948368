import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons';
import Button from '../button/Button';
import * as S from './styled';
// @ts-ignore
import imageSrc from '../../../assets/images/logo_tixxio.png';

interface FullModalWrapperProps {
  title?: string;
  onSave: () => void;
  onCancel?: any;
  onClose?: () => void;
  children: React.ReactNode;
  showItems?: string[];
  isScrolled?: boolean;
  height100?: boolean;
  minWidthHeader?: string;
  withLogo?: boolean;
  withClickLogo?: boolean;
}

export const FullModalWrapper: React.FC<FullModalWrapperProps> = ({
  title,
  onSave,
  onCancel,
  onClose,
  children,
  showItems = ['logoLabel', 'title', 'cancel', 'save', 'profile'],
  isScrolled = true,
  height100,
  minWidthHeader = 'unset',
  withLogo = true,
}) => {
  const {t} = useTranslation();
  const menuRef = useRef(null);

  const saveButton = showItems?.includes('save') && (
    <S.Save>
      <Button
        btnClass="btn-classic"
        bgColor={'#307feb'}
        onClick={() => onSave()}>
        {t('Save')}
      </Button>
    </S.Save>
  );

  const cancelButton = showItems?.includes('cancel') && (
    <S.Cancel>
      <Button
        btnClass="btn-classic"
        inverted
        onClick={onCancel || onClose || undefined}>
        {t('Cancel')}
      </Button>
    </S.Cancel>
  );

  const closeIcon = (
    <S.Close onClick={onClose || onCancel || undefined} className={'closeIcon'}>
      <FontAwesomeIcon className="icon" icon={faArrowRightFromBracket} />
    </S.Close>
  );

  const logoIcon = <S.Logo src={imageSrc} alt="logo" />;

  const logoLabel = showItems.includes('logoLabel') && (
    <S.LogoLabel>Hugg Blankets</S.LogoLabel>
  );

  const titleContainer = title && <S.Title>{title}</S.Title>;

  const leftContainer = (
    <S.Left>
      {closeIcon}
      {withLogo ? <S.Divider /> : null}
      {withLogo ? logoIcon : null}
      {withLogo ? logoLabel : null}
      {title && <S.Divider />}
      {title && titleContainer}
    </S.Left>
  );

  const buttons = (showItems.includes('cancel') ||
    showItems.includes('save')) && (
    <S.Buttons>
      {cancelButton}
      {saveButton}
    </S.Buttons>
  );

  const profile = showItems.includes('profile') && null;

  const header = (
    <S.Header $minWidthHeader={minWidthHeader}>
      {leftContainer}
      {buttons}
    </S.Header>
  );

  return (
    <S.RootContainer>
      {header}
      {children}
    </S.RootContainer>
  );
};
