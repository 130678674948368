import React, {useMemo, useRef, useState} from 'react';
import {useNavigate, useLocation, NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as IconEvents} from '../../assets/svg/header/Events-icon.svg';
import {ReactComponent as IconNotification} from '../../assets/svg/header/Notification-icon.svg';
import LogoTixxo from '../../assets/svg/LogoTixxio.svg';
import * as S from './styled';
import userLogo from '../../assets/images/userLogo.png';
import {profileMenuList} from './constants/profileMenuList';
import {notificationList} from './mock/notificationList';
import {useOutsideClick} from '../../hooks';
import {onRemoveText} from './utils/utils';
import {navigateTo} from '../../utils';
import {
  SideBarItemImg,
  SideBarSettings,
  HeaderTitleSettings,
} from '../../components/common/sidebar/styled';
// import {settingsData} from '../mocks';
import {SearchField} from '../../components/common/searchField';

export const Header = ({user, onClickLogOut}) => {
  const [menu, setMenu] = useState(false);
  const [notification, setNotification] = useState(false);
  const [initialsUser, setInitalsUser] = useState(null);
  const menuRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const userName = user?.name ? user.name : '';
  const pathName = location.pathname.split('/')[1];
  const isPageSettings = pathName === 'settings';

  useOutsideClick(menuRef, setMenu, false);
  useOutsideClick(notificationRef, setNotification, false);
  const closeIcon = (
    <S.Close onClick={() => navigate('/')}>
      <FontAwesomeIcon className="icon" icon={faArrowRightFromBracket} />
    </S.Close>
  );

  // const headerTitleSettings = (
  //   <SideBarSettings>
  //     <NavLink to="/settings">
  //       <SideBarItemImg>{settingsData.icon}</SideBarItemImg>
  //       <HeaderTitleSettings>{settingsData.title}</HeaderTitleSettings>
  //     </NavLink>
  //   </SideBarSettings>
  // );

  useMemo(() => {
    setInitalsUser(user?.name.replace(/[^A-Z]+/g, ''));
  }, []);

  return (
    <>
      <S.Header>
        <S.HeaderLogo
          onClick={() => {
            navigate('/');
          }}
          $settingsActive={isPageSettings}>
          {isPageSettings && closeIcon}
          <img src={LogoTixxo} alt="logo-tixxio" />
          <h3>Control Panel</h3>
          {/*{isPageSettings && headerTitleSettings}*/}
        </S.HeaderLogo>
        {!isPageSettings && (
          <S.HeaderOptionBlock>
            <S.HeaderProfile
              onClick={() => setMenu(prevState => !prevState)}
              ref={menuRef}>
              {user?.avatar ? (
                <img src={user.avatar} alt="user-avatar" />
              ) : (
                <S.BackgroundIconUser>{initialsUser}</S.BackgroundIconUser>
              )}
              <h3 style={{minWidth: '90px'}}>
                {userName !== 'undefined  undefined'
                  ? onRemoveText(userName)
                  : ''}
              </h3>
              <S.HeaderProfileMenu $active={menu}>
                {profileMenuList?.map(item => (
                  <S.HeaderMenuItem
                    onClick={() => {
                      if (item.title === 'Log Out') {
                        onClickLogOut();
                      } else {
                        navigate(item.link);
                      }
                    }}
                    key={item.id}>
                    <img src={item.icon} alt="icon" />
                    <h4>{item.title}</h4>
                  </S.HeaderMenuItem>
                ))}
              </S.HeaderProfileMenu>
            </S.HeaderProfile>
          </S.HeaderOptionBlock>
        )}
      </S.Header>
    </>
  );
};
