import {createSlice, createAsyncThunk, AsyncThunk} from '@reduxjs/toolkit';
import {showErrorNotificationV2} from '../../utils';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  addProduct,
  addPropertiesToProduct,
  cloneProduct,
  createCategory,
  createProductGroup, createProductOption,
  createProductType,
  createProductVariants,
  createSupplier,
  deleteProduct,
  deleteProductCategory,
  deleteProductCategoryImages,
  deleteProductGroup,
  deleteProductHeaderImages,
  deleteProductImages, deleteProductOption,
  deleteProductType,
  deleteProductVariantImages,
  deleteSupplier,
  getProduct,
  getProductCategories,
  getProductCategory,
  getProductGroup,
  getProductGroups, getProductOption,
  getProductOptions,
  getProductProperties,
  getProducts,
  getProductStatuses, getProductType,
  getProductTypesList,
  getProductVariant,
  getProductVariants,
  getSuppliers,
  removePropertiesFromProduct,
  updateProduct,
  updateProductCategory,
  updateProductGroup,
  updateProductImages, updateProductOption,
  updateProductType,
  updateProductVariant,
  updateProductVariantImages,
  updateSupplier, updateValueToProductOption,
  uploadLogoSupplier,
  uploadProductCategoryImages,
  uploadProductHeaderImages,
  uploadProductImages,
  uploadProductMockups,
  uploadProductVariantImages,
} from '../../api/helpers/marketing';

export const fetchMarketingProductList = createAsyncThunk(
  'fetchMarketingProductList',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProducts(payload?.data),
    }),
);

export const fetchMarketingCurrentProduct = createAsyncThunk(
  'fetchMarketingCurrentProduct',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: undefined}),
      create: async () => await addProduct(payload?.data),
      update: async () => await updateProduct(payload?.id, payload?.data),
      delete: async () => await deleteProduct(payload?.id),
      default: async () => await getProduct(payload?.id),
      clone: async () => await cloneProduct(payload?.id),
      addPropertiesToProduct: async () =>
        await addPropertiesToProduct(payload?.id, payload?.data),
      removePropertiesFromProduct: async () =>
        await removePropertiesFromProduct(payload?.id, payload?.data),
      createProductVariants: async () =>
        await createProductVariants(payload?.id, payload?.data),
    }),
);

export const fetchMarketingProductCategory = createAsyncThunk(
  'fetchMarketingProductCategory',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      create: async () => await createCategory(payload?.data),
      update: async () =>
        await updateProductCategory(payload?.id, payload?.data),
      delete: async () => await deleteProductCategory(payload?.id),
      default: async () => await getProductCategory(payload?.id),
      uploadCategoryImage: async () =>
        await uploadProductCategoryImages(payload?.id, payload?.data),
      deleteCategoryImage: async () =>
        await deleteProductCategoryImages(payload?.id, payload?.data),
    }),
);

export const fetchMarketingProductCategories = createAsyncThunk(
  'fetchMarketingProductCategories',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProductCategories(payload),
    }),
);
export const fetchMarketingProductGroups = createAsyncThunk(
  'fetchMarketingProductGroups',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProductGroups(payload),
    }),
);
export const fetchMarketingProductGroup = createAsyncThunk(
  'fetchMarketingProductGroup',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      create: async () => await createProductGroup(payload?.data),
      update: async () => await updateProductGroup(payload?.id, payload?.data),
      delete: async () => await deleteProductGroup(payload?.id),
      default: async () => await getProductGroup(payload?.id),
    }),
);
export const fetchMarketingProductStatuses = createAsyncThunk(
  'fetchMarketingProductStatuses',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProductStatuses(payload),
    }),
);
export const fetchMarketingProductOptionList = createAsyncThunk(
  'fetchMarketingProductOptionList',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProductOptions(payload),
    }),
);
export const fetchMarketingProductOption = createAsyncThunk(
  'fetchMarketingProductOption',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () => updateProductOption(payload?.id, payload.data),
      create: async () => createProductOption(payload?.data),
      delete: async () => deleteProductOption(payload?.id),
      updateOptionValue: async () =>
        updateValueToProductOption(payload?.oid, payload?.id, payload.data),
      default: async () => await getProductOption(payload?.id),
    }),
);
export const fetchMarketingProductOptionsList = createAsyncThunk(
  'fetchMarketingProductOptionsList',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProductOptions(payload.data),
    }),
);
export const fetchMarketingProductProperties = createAsyncThunk(
  'fetchMarketingProductProperties',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProductProperties(payload),
    }),
);
export const fetchMarketingProductVariants = createAsyncThunk(
  'fetchMarketingProductVariants',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      update: async () =>
        await updateProductVariant(payload?.id, payload?.mid, payload?.data),
      default: async () => await getProductVariants(payload?.id, payload?.data),
    }),
);
export const fetchMarketingProductVariant = createAsyncThunk(
  'fetchMarketingProductVariant',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () =>
        await updateProductVariant(payload?.id, payload?.mid, payload?.data),
      default: async () => await getProductVariant(payload?.id),
    }),
);
export const fetchMarketingProductHeaderImages = createAsyncThunk(
  'fetchMarketingProductHeaderImages',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      upload: async () =>
        await uploadProductHeaderImages(payload?.id, payload?.data),
      delete: async () =>
        await deleteProductHeaderImages(payload?.id, payload?.data),
    }),
);
export const fetchMarketingProductVariantImages = createAsyncThunk(
  'fetchMarketingProductVariantImages',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      delete: async () =>
        await deleteProductVariantImages(
          payload?.id,
          payload?.vid,
          payload?.data,
        ),
      update: async () =>
        await updateProductVariantImages(
          payload?.id,
          payload?.mid,
          payload?.data,
        ),
      default: async () =>
        await uploadProductVariantImages(
          payload?.id,
          payload?.vid,
          payload?.data,
        ),
    }),
);
export const fetchMarketingProductImages = createAsyncThunk(
  'fetchMarketingProductImages',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      update: async () => updateProductImages(payload?.id, payload.data),

      upload: async () => await uploadProductImages(payload?.id, payload?.data),
      delete: async () => await deleteProductImages(payload?.id, payload?.data),
    }),
);

export const fetchMarketingProductMockups = createAsyncThunk(
  'fetchMarketingProductMockups',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      upload: async () =>
        await uploadProductMockups(payload?.id, payload?.data),
    }),
);

export const fetchMarketingSuppliers = createAsyncThunk(
  'fetchMarketingSuppliers',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getSuppliers(payload?.data),
    }),
);
export const fetchMarketingSupplier = createAsyncThunk(
  'fetchMarketingSupplier',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      create: async () => await createSupplier(payload?.data),
      update: async () => await updateSupplier(payload?.id, payload?.data),
      delete: async () => await deleteSupplier(payload?.id),
      uploadLogo: async () =>
        await uploadLogoSupplier(payload?.id, payload?.data),
      default: async () => await getSuppliers(payload?.data),
    }),
);
export const fetchMarketingProductTypes = createAsyncThunk(
  'fetchMarketingProductTypes',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: []}),
      default: async () => await getProductTypesList(payload.data),
    }),
);

export const fetchProductType = createAsyncThunk(
  'fetchProductType',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: async () => ({data: null}),
      update: async () => updateProductType(payload?.id, payload.data),
      create: async () => createProductType(payload?.data),
      delete: async () => deleteProductType(payload?.id),
      default: async () => getProductType(payload?.id),
    }),
);

export const marketingSlice = createSlice({
  name: 'marketing',
  initialState: {
    products: {data: []},
    product: {data: null},
    category: {data: null},
    categories: {data: []},
    groups: {data: []},
    group: {data: null},
    statuses: {data: []},
    optionList: {data: []},
    productOptions: {data: []},
    productOption: {data: null},
    properties: {data: []},
    variants: {data: []},
    variant: {data: null},
    headerImages: {data: []},
    variantImages: {data: []},
    productImages: {data: []},
    mockupsImages: {data: []},
    pagination: {data: []},
    suppliers: {data: []},
    supplier: {data: null},
    productTypes: {data: []},
    productType: {data: null},
  },
  reducers: {
    setPagination: (state, action) => {
      state.pagination.data = action.payload;
    },
  },
  extraReducers: (builder: any): any => {
    addBuilderCases(builder, fetchMarketingProductList, 'products');
    addBuilderCases(builder, fetchMarketingCurrentProduct, 'product');
    addBuilderCases(builder, fetchMarketingProductCategory, 'category');
    addBuilderCases(builder, fetchMarketingProductCategories, 'categories');
    addBuilderCases(builder, fetchMarketingProductGroups, 'groups');
    addBuilderCases(builder, fetchMarketingProductGroup, 'group');
    addBuilderCases(builder, fetchMarketingProductStatuses, 'statuses');
    addBuilderCases(builder, fetchMarketingProductOptionList, 'optionList');
    addBuilderCases(builder, fetchMarketingProductOption, 'productOption');
    addBuilderCases(
      builder,
      fetchMarketingProductOptionsList,
      'productOptions',
    );
    addBuilderCases(builder, fetchMarketingProductProperties, 'properties');
    addBuilderCases(builder, fetchMarketingProductVariants, 'variants');
    addBuilderCases(builder, fetchMarketingProductVariant, 'variant');
    addBuilderCases(builder, fetchMarketingProductHeaderImages, 'headerImages');
    addBuilderCases(builder, fetchMarketingProductImages, 'productImages');
    addBuilderCases(builder, fetchMarketingProductMockups, 'mockupsImages');
    addBuilderCases(builder, fetchMarketingSuppliers, 'suppliers');
    addBuilderCases(builder, fetchMarketingSupplier, 'supplier');
    addBuilderCases(builder, fetchMarketingProductTypes, 'productTypes');
    addBuilderCases(builder, fetchProductType, 'productType');
  },
});

export const {setPagination} = marketingSlice.actions;
