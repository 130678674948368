export const HOME = '/';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const DASHBOARD = '/dashboard';
export const CLIENTS = '/clients';
export const BILLING = '/billing';
export const BILLING_INVOICES = '/invoices';
export const BILLING_TRANSACTIONS = '/transactions';
export const SETTINGS_PAYMENT_METHODS = '/payment-methods';
export const ACCOUNTING_PROGRAM = '/eboekhouden';
export const INVOICE_TYPES = '/invoice_types';
export const REPORTS = '/reports';
export const PRODUCTS = '/products';
export const VIEWTEMPLATE = '/designer/:id';
export const VIEWPRODUCT = '/products/:id';
export const DESIGNER = '/designer';
export const COLLECTIONS = '/collections';
export const PRODUCT_TYPES = '/products/product-types';
export const VIEW_PRODUCT_TYPE = '/product-types/:id';
export const PRODUCT_OPTIONS = '/product-options';
export const VIEW_OPTION = '/product-options/:id';
export const CATEGORIES = '/categories';
export const BRANDS = '/brands';
export const SETTINGS = '/settings';
export const SUPERUSERS = '/superusers';
export const COUNTRIES = '/settings/countries';
export const PLATFORM = '/settings/platform';
export const SHIPPING = '/shipping';
export const SHIPPING_RATES = '/shipping-rates';
export const SUPPLIERS = '/settings/suppliers';
export const ROLES = '/roles';
export const SETTINGS_TEMPLATES = '/templates';
export const CLIENT_SETTINGS = '/settings/clientsettings';
//export const ACCOUNTING_PROGRAM = '/settings/accounting-program';
export const SUB_COLLECTIONS = '/collections/:id/subcollections';
export const VIEW_COLLECTION = '/collections/:id';
export const SUB_CATEGORIES = '/categories/:id/subcategories';
export const VIEW_CATEGORY = '/categories/:id';
export const SUPPORT = '/helpcenter';
export const PRINTING_COMPANY = '/settings/printing-company';
export const PRINTING_PROFILES = '/printing-profile';
export const CHANNELS = '/channels';

