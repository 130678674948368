import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//import {useSelector} from 'react-redux';
import {HeaderComponent} from './Header';
import {Sidebar as SidebarCommon} from '../components/sidebar/Sidebar';
import {Footer} from '../components/footer/Footer';
import {Breadcrumbs} from '../components/common/breadcrumbs/Breamcrumbs';
//import SidebarChat from '../domains/Support/сomponents/Sidebar/SidebarChat';
import {getCurrentPage, navigateTo} from '../utils';
import {paths} from '../constants';
import {links, copyright, generalMenuData} from './mocks';
//import {useOutsideClick} from '../hooks';
import {Sidebar} from '../components/common/sidebar';
import * as S from './styled/styled';
//import Api from '../api/ApiV2';
import {Header} from './Hugg/Header';
import ErrorBoundary from '../components/common/errorBoundary/ErrorBoundary';
import * as GS from './styled';

//const api = new Api('Account');

const Layout = ({children, title, showDescription, user, uniquePath}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpenedSidebar, setIsOpenedSidebar] = useState(true);
  const {t} = useTranslation(['translation']);
  const [breadcrumbs, setBreadcrumbs] = useState(getBreadcrumbsData());
  const [windowWidth, setWindowWidth] = useState(getWindonwSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(getWindonwSize());
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowWidth]);

  function getWindonwSize() {
    const {innerWidth} = window;
    return innerWidth;
  }

  const onClickShortCutHandler = item => {
    if (item.path) {
      navigate(item.path);
    }
  };

  const rootNotification = (
    <GS.ErrorBounderWrapper>
      <h3>{t('Something went wrong.')}</h3>
      <h4>{t('Please reload the page!')}</h4>
    </GS.ErrorBounderWrapper>
  );

  function getBreadcrumbsData() {
    const pathStrings = location.pathname
      .split('/')
      .map(item =>
        location.pathname.slice(
          0,
          item ? location.pathname.indexOf(item) + item.length : 1,
        ),
      );

    return paths(t).reduce((acc, curr) => {
      const index = pathStrings.findIndex(element => element === curr.url);
      if (index !== -1) {
        if (index !== pathStrings.length - 1) {
          return [...acc, curr];
        }
        return [...acc, {...curr, url: ''}];
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    //setBreadcrumbs(getBreadcrumbsData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, t]);

  useEffect(() => {
    if (uniquePath) {
      setBreadcrumbs(prevState => {
        return prevState.concat(uniquePath);
      });
    }
  }, [uniquePath, t]);

  const onClickLogOut = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('client');
    localStorage.removeItem('locale');
    window.location.href = '/';
  };

  const currentPage = getCurrentPage(location);

  return (
    <S.Layout $containerWidth={windowWidth} $isOpenedSidebar={isOpenedSidebar}>
      <div className="layout">
        <Header user={user} onClickLogOut={onClickLogOut} />
        <div className="layout-sidebar">
          <S.SideBar>
            <Sidebar
              data={generalMenuData(t)}
              onOpenSideBar={value => setIsOpenedSidebar(value)}
              isOpenedSidebar={isOpenedSidebar}
            />
          </S.SideBar>
        </div>
        <div className="layout-content">
          <div>
            {showDescription && (
              <div className="layout-titleBlock">
                <Breadcrumbs data={breadcrumbs} />
                <h1 className="layout-pageName">{title}</h1>
              </div>
            )}
            <ErrorBoundary
              key={location?.pathname}
              notification={rootNotification}>
              <S.LayoutContent>{children}</S.LayoutContent>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </S.Layout>
  );
};

export default Layout;
