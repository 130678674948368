import ApiV2 from '../ApiV2';

export const getClients = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.client.clients.get(payload);
  return response?.data;
};

export const getClient = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.get(id);
  return response?.data;
};

export const createClient = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.client.clients.post(data);
  return response?.data;
};

export const updateClient = async (id: number, data: object): Promise<any> => {
  const response = await ApiV2.cp.client.clients.patch(id, data);
  return response?.data;
};

export const deleteClient = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.delete(id);
  return response?.data;
};

export const suspendClient = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.suspend.post(id);
  return response?.data;
};

export const unsuspendClient = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.unsuspend.post(id);
  return response?.data;
};

export const getClientStatuses = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.client.clientStatuses.get(payload);
  return response?.data;
};

export const getClientTypes = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.client.clientTypes.get(payload);
  return response?.data;
};
export const createClientType = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.client.clientTypes.post(payload);
  return response?.data;
};
export const updateClientType = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clientTypes.patch(id, payload);
  return response?.data;
};
export const getClientType = async (id: any): Promise<any> => {
  const response = await ApiV2.cp.client.clientTypes.get(id);
  return response?.data;
};
export const deleteClientType = async (id: any): Promise<any> => {
  const response = await ApiV2.cp.client.clientTypes.delete(id);
  return response?.data;
};
export const getUsers = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.client.clients.users.get(payload);
  return response?.data;
};
export const getUser = async (id: number, uid: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.users.get({id, uid});
  return response?.data;
};
export const createUser = async (id: number, data: object): Promise<any> => {
  const response = await ApiV2.cp.client.clients.users.post(id, data);
  return response?.data;
};
export const deleteUser = async (id: number, uid: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.users.delete({id, uid});
  return response?.data;
};
export const updateUser = async (
  id: number,
  uid: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.users.patch({id, uid}, data);
  return response?.data;
};

export const getShops = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.client.shops.get(data);
  return response?.data;
};
export const getShop = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.shops.get(id);
  return response?.data;
};

export const createShop = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.client.shops.post(data);
  return response?.data;
};

export const updateShop = async (id: number, data: object): Promise<any> => {
  const response = await ApiV2.cp.client.shops.patch(id, data);
  return response?.data;
};

export const deleteShop = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.shops.delete(id);
  return response?.data;
};

export const getApplications = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.client.applications.get(data);
  return response?.data;
};

export const getApplication = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.applications.get(id);
  return response?.data;
};

export const createApplication = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.client.applications.post(data);
  return response?.data;
};
export const updateApplication = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.applications.patch(id, data);
  return response?.data;
};

export const deleteApplication = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.applications.delete(id);
  return response?.data;
};

export const getSettings = async (id: number, data: object): Promise<any> => {
  const response = await ApiV2.cp.client.clients.settings.get(id, data);
  return response?.data;
};

export const createSettings = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.settings.post(id, data);
  return response?.data;
};

export const updateSettings = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.settings.put(id, data);
  return response?.data;
};

export const getApplicationTypes = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.client.applicationTypes.get(data);
  return response?.data;
};

export const getClientAddresses = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.addresses.get(id, data);
  return response?.data;
};

export const getAddressTypes = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.client.addressType.get(payload);
  return response?.data;
};

export const forgotPassword = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.users.passwordForgot.post(id, data);
  return response.data;
};

export const getClientSubscriptionBundles = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.subscriptionBundles.get(
    id,
    payload,
  );
  return response.data;
};
export const addNewSubscriptionBundleToClient = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.subscriptionBundles.post(
    id,
    payload,
  );
  return response.data;
};
export const getClientSubscriptionItems = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.subscriptions.get(id, payload);
  return response.data;
};
export const addSubscriptionItemToClientBundle = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.subscriptions.post(
    id,
    payload,
  );
  return response.data;
};
export const updateSubscriptionItemToClientBundle = async (
  id: number,
  subid: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.subscriptions.patch(
    {id, subid},
    payload,
  );
  return response.data;
};
export const getClientLimitsStatus = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.limits.get(id);
  return response.data;
};

export const updateClientLimitsStatus = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.limits.patch(id, data);
  return response.data;
};

export const getUserPermissions = async (id: number) => {
  const response = await ApiV2.cp.client.users.permissions.get({id});
  return response?.data;
};

export const updateUserPermissions = async (id: number, data: any) => {
  const response = await ApiV2.cp.client.users.permissions.put({id}, data);
  return response?.data;
};

export const getShopSettings = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.shops.settings.get(id, data);
  return response?.data;
};

export const createShopSettings = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.shops.settings.post(id, data);
  return response?.data;
};

export const updateShopSettings = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.shops.settings.put(id, data);
  return response?.data;
};

export const getClientShipmentRates = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.shippingRates.get(id, data);
  return response?.data;
};
export const updateClientShipmentRate = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.shippingRates.put(id, data);
  return response?.data;
};

export const getClientShipmentZones = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.client.clients.shippingZones.get(id);
  return response?.data;
};
export const updateClientShipmentZone = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.client.clients.shippingZones.put(id, data);
  return response?.data;
};

export const getShopClientAddresses = async (id: number) => {
  const response = await ApiV2.cp.client.shops.clientAddresses.get(id);
  return response?.data;
};

export const assignShopClientAddress = async (
  shopId: number,
  addressId: number,
) => {
  const response = await ApiV2.cp.client.shops.clientAddresses.post({
    id: shopId,
    aid: addressId,
  });
  return response?.data;
};

export const removeShopClientAddress = async (
  shopId: number,
  addressId: number,
) => {
  const response = await ApiV2.cp.client.shops.clientAddresses.delete({
    id: shopId,
    aid: addressId,
  });
  return response?.data;
};

export const getAddresses = async (data: any) => {
  const response = await ApiV2.cp.client.addresses.get(data);
  return response?.data;
};
export const deleteAddress = async (id: any) => {
  const response = await ApiV2.cp.client.addresses.delete(id);
  return response?.data;
};

export const updateAddress = async (id: number, data: any) => {
  const response = await ApiV2.cp.client.addresses.patch(id, data);
  return response?.data;
};
export const createAddress = async (data: any) => {
  const response = await ApiV2.cp.client.addresses.post(data);
  return response?.data;
};

export const getSuppliers = async (id: string | number) => {
  const response = await ApiV2.cp.client.clients.suppliers.get(id);
  return response?.data;
};
export const updateSuppliers = async (id: string | number, data: any) => {
  const response = await ApiV2.cp.client.clients.suppliers.put(id, data);
  return response?.data;
};

export const getApplicationSettings = async (id: string | number ) => {
  const response = await ApiV2.cp.client.applications.settings.get({id});
  return response?.data;
};

export const updateApplicationSettings = async (id: string | number, data: any) => {
  const response = await ApiV2.cp.client.applications.settings.put({id}, data);
  return response?.data;
};

export const createUpdateApplicationSettings = async (id: string | number, data: any) => {
  const response = await ApiV2.cp.client.applications.settings.post({id}, data);
  return response?.data;
};
