//React
import React, {useState, useEffect, useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Tooltip} from '@mui/material';

//Mocks

// Styles
import * as S from './styled';

export const Sidebar = ({data, onOpenSideBar, isOpenedSidebar}) => {
  const [activeTabSideBar, setActiveTabSideBar] = useState(null);
  const location = useLocation();

  const pathName = location.pathname?.split('/')[1];
  const doubleUrl = location.pathname?.split('/').slice(0, 3).join('/');

  const conditionUrl = elem => elem.slug?.split('/')[1] === pathName;
  const conditionUrlsSettings = elem => elem.slug === doubleUrl;

  const [activeItem, setActiveItem] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [focused, setFocused] = useState(false);

  const toggleSideMenu = () => {
    onOpenSideBar(!isOpenedSidebar);
  };
  const queryParameters = new URLSearchParams(location.search);
  const tabOrderUrl = queryParameters.get('tab') || 1;
  const changeStateSidebar = state => {
    setActiveSidebar(state);
  };

  useEffect(() => {
    if (pathName === '') {
      setActiveItem(2);
    } else {
      let navMenu = [];
      data.map(item => navMenu.push(...item.elements));

      const firstActiveItem = [...navMenu].find(
        item =>
          conditionUrl(item) ||
          item?.subItems?.find(conditionUrl) ||
          item?.subElements?.find(i =>
            i?.subItems?.length
              ? i.subItems.find(conditionUrlsSettings)
              : conditionUrlsSettings(i),
          ),
      );

      const catalogsSlug = firstActiveItem?.subElements?.find(i =>
        i?.subItems?.length
          ? i.subItems?.find(conditionUrlsSettings)
          : conditionUrlsSettings(i),
      );

      if (catalogsSlug) {
        setActiveItem(catalogsSlug?.id);
      } else {
        setActiveItem(firstActiveItem?.id);
        setOpenSubmenu(firstActiveItem?.id);
      }
    }

    // setActiveTabSideBar(activeItemSidebar?.id);
  }, [window.location.pathname]);

  const sidebarMenuItems = data?.length
    ? data.map(groupMenu => (
        <S.NavWrapper key={groupMenu?.id}>
          <S.NavHeader
            $isShow={isOpenedSidebar}
            $isAbsent={groupMenu?.header === ''}>
            <S.BlockTitle $isShow={isOpenedSidebar}>
              {groupMenu?.header}
            </S.BlockTitle>
          </S.NavHeader>
          {groupMenu.elements.map(item => {
            const tabImg = <S.SideBarItemImg>{item.icon}</S.SideBarItemImg>;
            return (
              <S.SidebarElemList key={item?.id}>
                <S.SidebarItem
                  $active={activeItem === item?.id}
                  $isOpenedSidebar={isOpenedSidebar}>
                  <Link
                    to={item.slug}
                    onClick={() => {
                      setOpenSubmenu(item.id);
                      setActiveItem(item?.id);
                      onOpenSideBar(true);
                    }}>
                    {isOpenedSidebar ? (
                      tabImg
                    ) : (
                      <Tooltip
                        title={item.title}
                        arrow
                        placement={'right'}
                        disableInteractive>
                        {tabImg}
                      </Tooltip>
                    )}
                    <S.SideBarItemTitle>{item.title}</S.SideBarItemTitle>
                  </Link>
                </S.SidebarItem>
                {openSubmenu === item?.id && item?.subItems?.length && (
                  <S.SidebarSubMenu $isOpenedSidebar={isOpenedSidebar}>
                    {item.subItems.map(subItem => (
                      <S.SubMenuItem
                        key={subItem.id}
                        $selected={
                          doubleUrl === subItem.slug ||
                          +tabOrderUrl === subItem.id
                        }>
                        <Link to={subItem.slug}>
                          <span>{subItem.title}</span>
                        </Link>
                      </S.SubMenuItem>
                    ))}
                  </S.SidebarSubMenu>
                )}
              </S.SidebarElemList>
            );
          })}
        </S.NavWrapper>
      ))
    : null;

  return (
    <>
      <S.SidebarWrapper
        $isOpen={isOpenedSidebar}
        $activeSidebar={focused}
        onMouseEnter={() => {
          changeStateSidebar(true);
        }}
        onMouseLeave={() => {
          changeStateSidebar(false);
        }}>
        <S.SidebarList className="sidebar-list">
          {sidebarMenuItems}
        </S.SidebarList>
      </S.SidebarWrapper>
      <S.IconOpenMenu
        $isOpened={isOpenedSidebar}
        $activeSidebar={activeSidebar}
        onMouseEnter={() => {
          setActiveSidebar(true);
          setFocused(true);
        }}
        onMouseLeave={() => {
          setActiveSidebar(false);
          setFocused(false);
        }}
        onClick={() => {
          toggleSideMenu();
          setActiveSidebar(true);
        }}>
        <FontAwesomeIcon icon={faChevronRight} />
      </S.IconOpenMenu>
    </>
  );
};
