import React, {FunctionComponent} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import * as S from './styled';
import {MuiDropdownProps} from './types';

const MuiDropdown: FunctionComponent<MuiDropdownProps> = props => {
  const {data, onChange, value, disabled, onResetClick, onInputChange} = props;
  const options = (data || []).map(i => ({label: i?.name, value: i?.id}));
  return (
    <S.Select>
      <Autocomplete
        {...props}
        options={options}
        onInputChange={onInputChange}
        sx={{minWidth: 200, width: '100%'}}
        value={value?.name || null}
        onChange={(e, v) => {
          if (v === null && onResetClick) onResetClick();
          else onChange(data.find(i => i?.id === v?.value));
        }}
        renderInput={params => (
          <TextField
            style={{backgroundColor: disabled ? '#f5f5f5' : '#fff'}}
            {...params}
            placeholder="Select..."
            size="small"
            variant={'outlined'}
            hiddenLabel={false}
          />
        )}
      />
    </S.Select>
  );
};

export default MuiDropdown;
